<template>
  <el-dialog v-model="dialogVisible" title="條件搜尋" width="650px">
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      status-icon
      hide-required-asterisk
      label-position="top"
      class="search-form"
    >
      <el-form-item
        class="form-item"
        prop="date"
        :rules="[
          {
            required: true,
            message: '請選擇交易日期',
            trigger: 'blur',
          },
        ]"
      >
        <template #label>
          <span class="label">交易日期</span>
        </template>
        <el-date-picker
          v-model="ruleForm.date"
          type="daterange"
          unlink-panels
          range-separator="－"
          start-placeholder="開始日期"
          end-placeholder="結束日期"
          :shortcuts="shortcuts"
          format="YYYY/MM/DD"
          value-format="YYYY/MM/DD"
          :disabled-date="disabledDate"
          :default-value="timeDefaultShow"
        />
      </el-form-item>
      <el-form-item class="form-item">
        <template #label>
          <span class="label">門市</span>
        </template>
        <el-cascader
          v-model="ruleForm.shop_id"
          :options="getStoreList"
          :show-all-levels="false"
          placeholder="請選擇或輸入門市名稱、代碼"
          style="width: 100%"
          clearable
          filterable
          collapse-tags
          collapse-tags-tooltip
          :props="{ emitPath: false, multiple: true }"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="close">取消</el-button>
        <el-button
          type="primary"
          @click="handleSearch(ruleFormRef)"
          :disabled="!checkSubmit()"
          style="margin-left: 25px"
        >
          搜尋
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script>
import { inject, ref } from 'vue';
import { useGetters, useActions } from '@/utils/mapStore';
import { useRouter } from 'vue-router';
export default {
  name: 'report-dialog',
  setup() {
    const dialogVisible = inject('dialogVisible');
    const close = inject('close');
    const { cusProps } = inject('cusProps');
    //設定參數
    const ruleFormRef = ref(null);
    const ruleForm = ref(null);
    //設定初始值
    const getSearchCondition = useGetters('report', ['getSearchCondition']);
    if (dialogVisible) {
      ruleForm.value = JSON.parse(JSON.stringify(getSearchCondition.value));
    }

    //店家選項-----------------------------------------------------
    const getStoreList = useGetters('common', ['getStoreList']);

    //日期相關-----------------------------------------------------
    //日期快捷
    const shortcuts = [
      {
        text: '前一天',
        value: () => {
          const day = new Date();
          const end = new Date(day.getTime() - 3600 * 1000 * 24);
          const start = new Date(day.getTime() - 3600 * 1000 * 24);
          return [start, end];
        },
      },
      {
        text: '當周',
        value: () => {
          const end = new Date();
          const start = new Date(end.getTime() - 3600 * 1000 * 24 * (end.getDay() === 0 ? 6 : end.getDay() - 1));
          return [start, end];
        },
      },
      {
        text: '當月',
        value: () => {
          const end = new Date();
          const start = new Date(`${end.toLocaleDateString().slice(0, 7)}/01`);
          return [start, end];
        },
      },
    ];
    //預設顯示日期
    const timeDefaultShow = new Date();
    timeDefaultShow.setMonth(new Date().getMonth() - 1);
    //禁用日期
    const disabledDate = (time) => {
      const today = new Date();
      if (time.getTime() > today.getTime()) return true;
      // else if (time.toLocaleDateString() === today.toLocaleDateString()) return true;
    };

    //驗證與檢查-----------------------------------------------------------------
    const checkSubmit = () => {
      let isValid;
      //未選擇任一條件
      isValid = !Object.keys(ruleForm.value).every((e) => !ruleForm.value[e] || ruleForm.value[e].length === 0);
      //未改變任一條件
      if (getSearchCondition.value && isValid) {
        isValid = !(JSON.stringify(ruleForm.value) === JSON.stringify(getSearchCondition.value));
      }
      //未選擇時間
      if (!ruleForm.value.date || ruleForm.value.date.length === 0) isValid = false;
      return isValid;
    };

    //送出
    const router = useRouter();
    const doSearchReportList = useActions('report', ['doSearchReportList']);
    const handleSearch = async (form) => {
      try {
        if (!form) return;
        await form.validate();
        // console.log(ruleForm.value);
        await doSearchReportList({ start: 0, search: ruleForm.value, type: 'search' });
        router.push('/order/report?page=1');
        close();
      } catch (e) {
        e;
      }
    };
    return {
      dialogVisible,
      close,
      cusProps,
      ruleFormRef,
      ruleForm,
      getStoreList,
      shortcuts,
      timeDefaultShow,
      disabledDate,
      checkSubmit,
      handleSearch,
    };
  },
};
</script>
<style lang="scss" scoped>
.search-form {
  display: grid;
  grid-template-rows: 100px 1fr;
  grid-auto-flow: column;
  grid-column-gap: 25px;
}
.form-item {
  @include basic-form-item;
}
.dialog-footer {
  @include full-button;
}
</style>
